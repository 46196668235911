@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: fit-content;
  max-width: 800px;
  max-height: 100%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background-color: rgb(230, 230, 230);
  outline: none;
  overflow: hidden;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.Collapsible {
  font-size: 3em;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75);
  cursor: pointer;
  border: 0.2rem solid #fff;
  border-radius: 2rem;
  padding: 0.5em;
  box-shadow: 0 0 .2rem #fff,
              0 0 .2rem #fff,
              0 0 2rem #FF678E,
              0 0 0.8rem #FF678E,
              0 0 2.8rem #FF678E,
              inset 0 0 1.3rem #FF678E;
}

@media only screen and (max-width: 1350px) {
  .Collapsible {
    font-size: 2em;
    border-radius: 1rem;
  }
}


@media only screen and (max-width: 950px) {
  .Collapsible {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 725px) {
  .Collapsible {
    font-size: 1.25em;
  }
}

@media only screen and (max-width: 580px) {
  .Collapsible {
    font-size: 1em;
  }
}

@media only screen and (max-width: 475px) {
  .Collapsible {
    font-size: 0.75em;
  }
}

@media only screen and (max-width: 360px) {
  .Collapsible {
    font-size: 0.5em;
  }
}

.swiper-slide {
  visibility: hidden;
  &.swiper-slide-prev,
  &.swiper-slide-active,
  &.swiper-slide-next {
      visibility: visible;
  }
}

