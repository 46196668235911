@import '../../styles/breakpoints.scss';

.mint-section-container {
  position: relative;
  text-align: center;
}

.comp-image {
  width: 100%;
}

.comp-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60%;

  .loading-container {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .connection-error-message {
    margin: 0em;
    padding: 0em;
    font-size: 2em;
    user-select: none;
    color: #fff;
    line-height: 1.6;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #ff678e, 0 0 80px #ff678e;

    @media screen and (max-width: map-get($media, 'tablets')) {
      font-size: 1.5em;
    }

    @media screen and (max-width: map-get($media, 'mobileLandscape')) {
      font-size: 1.25em;
    }

    @media screen and (max-width: map-get($media, 'mobilePortrait')) {
      font-size: 1em;
    }

    @media screen and (max-width: map-get($media, 'extraSmall')) {
      font-size: 0.75em;
    }
  }

  .minting-not-started-error-message {
    margin: 0em;
    padding: 0em;
    font-size: 2em;
    user-select: none;
    color: #fff;
    line-height: 1.6;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #ff678e, 0 0 80px #ff678e;

    @media screen and (max-width: map-get($media, 'tablets')) {
      font-size: 1.5em;
    }

    @media screen and (max-width: map-get($media, 'mobileLandscape')) {
      font-size: 1.25em;
    }

    @media screen and (max-width: map-get($media, 'mobilePortrait')) {
      font-size: 1em;
    }

    @media screen and (max-width: map-get($media, 'extraSmall')) {
      font-size: 0.75em;
    }
  }

  .mint-button {
    font-size: 2em;
    padding: 1em;
    background-color: transparent;
    border: 0.2em solid white;
    border-radius: 0.25em;
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #ff678e, 0 0 0.8rem #ff678e, 0 0 2.8rem #ff678e,
      inset 0 0 1.3rem #ff678e;
    color: white;
    cursor: pointer;
    user-select: none;

    @media screen and (max-width: map-get($media, 'largeDisplays')) {
      font-size: 1.5em;
    }

    @media screen and (max-width: map-get($media, 'tablets')) {
      font-size: 1.25em;
    }

    @media screen and (max-width: map-get($media, 'mobileLandscape')) {
      font-size: 1em;
    }

    @media screen and (max-width: map-get($media, 'mobilePortrait')) {
      font-size: 0.75em;
    }

    @media screen and (max-width: map-get($media, 'extraSmall')) {
      font-size: 0.5em;
    }

    &:focus {
      outline: none;
    }
  }

  .password-input {
    font-size: 2em;
    padding: 1em;
    background-color: transparent;
    border: 0.2em solid white;
    border-radius: 0.25em;
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #ff678e, 0 0 0.8rem #ff678e, 0 0 2.8rem #ff678e,
      inset 0 0 1.3rem #ff678e;
    color: white;

    @media screen and (max-width: map-get($media, 'largeDisplays')) {
      font-size: 1.5em;
    }

    @media screen and (max-width: map-get($media, 'tablets')) {
      font-size: 1.25em;
    }

    @media screen and (max-width: map-get($media, 'mobileLandscape')) {
      font-size: 1em;
    }

    @media screen and (max-width: map-get($media, 'mobilePortrait')) {
      font-size: 0.75em;
    }

    @media screen and (max-width: map-get($media, 'extraSmall')) {
      font-size: 0.5em;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    &:focus {
      outline: none;
    }
  }
}
