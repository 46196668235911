@import '../../../styles/breakpoints.scss';
@import '../../../styles/colors.scss';

.connect-wallet-container {
  border: 2px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 1em;
  position: absolute;
  vertical-align: middle;
  right: 2em;
  padding: 0.5em;
  display: flex;

  @media screen and (max-width: map-get($media, 'tablets')) {
    position: relative;
    text-align: center;
    right: 0em;
  }

  .connect-wallet-indicator {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5em;
    filter: blur(1px);
  }

  .connect-wallet-text {
    margin: 0em;
    padding: 0em;
    font-weight: 500;
    font-size: 1rem;
    align-self: center;
  }
}

.wallet-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: fit-content;
  max-height: 100%;
  transform: translate(-50%, -50%);
  outline: none;
  overflow: hidden;
  background: rgba(21, 13, 114, 0.13);
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  backdrop-filter: blur(15.4px);
  -webkit-backdrop-filter: blur(15.4px);
  border: 1px solid rgba(21, 13, 114, 1);
  animation: fadein 0.5s;

  z-index: 300;

  &__container {
    max-width: 85vw;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;

    &__close-button {
      width: 20px;
      height: 20px;
      cursor: pointer;
      justify-self: end;
      margin: 1em 2em 0em 0em;
    }

    &__item-container {
      display: flex;
      flex-wrap: nowrap;
      margin: 0em 2em;
      padding: 0em;
      cursor: pointer;
      &:last-child {
        margin: 0em 2em 2em 2em;

        @media screen and (max-width: map-get($media, 'extraSmall')) {
          margin: 0em 1.5em 1.5em 1.5em;
        }
      }

      @media screen and (max-width: map-get($media, 'extraSmall')) {
        margin: 0em 1.5em;
      }
    }

    &__item-name {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 3em;
      margin: 0em 0em 0em 1em;
      padding: 0;
      vertical-align: middle;
      color: white;

      @media screen and (max-width: map-get($media, 'mobilePortrait')) {
        font-size: 2em;
      }
    }

    &__item-image {
      height: 50px;
      width: 50px;

      @media screen and (max-width: map-get($media, 'extraSmall')) {
        height: 30px;
        width: 30px;
      }
    }

    &__error-message {
      color: white;
      font-family: 'Inter';
      padding: 0em 2em 1em 2em;
      font-size: 1.75em;

      @media screen and (max-width: map-get($media, 'tablets')) {
        font-size: 1.5em;
      }

      @media screen and (max-width: map-get($media, 'mobileLandscape')) {
        padding: 0em 1.5em 1em 1.5em;
      }

      @media screen and (max-width: map-get($media, 'mobilePortrait')) {
        padding: 0em 1em 1em 1em;
        font-size: 1.25em;
      }
    }


    &__sign-out {
      color: white;
      cursor: pointer;
      font-family: 'Inter';
      padding: 0em 2em 1em 2em;
      font-size: 3em;

      @media screen and (max-width: map-get($media, 'tablets')) {
        font-size: 1.5em;
      }

      @media screen and (max-width: map-get($media, 'mobileLandscape')) {
        padding: 0em 1.5em 1em 1.5em;
      }

      @media screen and (max-width: map-get($media, 'mobilePortrait')) {
        padding: 0em 1em 1em 1em;
        font-size: 1.25em;
      }
    }

    &__error-link {
      margin: 0em 0.5em;
    }




  }
}

.wallet-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 300;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
