.swiper-item-container {
  width: 100%;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background: radial-gradient(circle, rgba(255, 103, 142, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);

  @media screen and (max-width: 700px) {
    background: radial-gradient(circle, rgba(255, 103, 142, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  }

  flex-wrap: wrap;

  .swipe-item-image {
    width: 1500px;

    //Some horrible css styling
    //to be cleaned later down the track
    @media screen and (max-width: 2100px) {
      width: 1000px;
    }

    @media screen and (max-width: 1650px) {
      width: 800px;
    }

    @media screen and (max-width: 1360px) {
      width: 600px;
    }

    @media screen and (max-width: 780px) {
      width: 500px;
    }
  }

  .swipe-item-descriptor {
    margin: 0em;
    padding: 2em 0em 0em 0em;
    text-align: center;
    font-size: 1.5em;
    text-transform: capitalize;
    color: #fff;
    text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #150D72, 0 0 82px #150D72, 0 0 92px #150D72, 0 0 102px #150D72,
      0 0 151px #150D72;
  }
}
