@import '../../styles/breakpoints.scss';

.view-section-title {
    margin: 1em 0em 0em 0em;
	padding: 0;
	text-align: center;
	font-size: 5em;
    
    @media screen and (max-width: map-get($media, 'tablets')) {
        font-size: 4em;
    }
      
    @media screen and (max-width: map-get($media, 'mobilePortrait')) {
        font-size: 3.5em;
    }

}

.view-section-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.view-item-container {
    margin-top: 1em;
    border: 1.5px white solid;
    border-radius: 0.5em;
    padding: 1em;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    width: fit-content;
}

.view-item-error-message {
    max-width: 400px;
    padding: 0px;
    text-align: center;
    font-size: 1.5em;
    margin: auto 0;

    @media screen and (max-width: map-get($media, 'largeDisplays')) {
        max-width: 300px;
    }

    @media screen and (max-width: map-get($media, 'tablets')) {
        max-width: 250px;
    }

    @media screen and (max-width: map-get($media, 'mobilePortrait')) {
        max-width: 300px;
    }

    @media screen and (max-width: map-get($media, 'extraSmall')) {
        max-width: 200px;
    }
}

.view-item-image {
    width: 400px;
    height: 400px;

    @media screen and (max-width: map-get($media, 'largeDisplays')) {
        width: 300px;
        height: 300px;
    }

    @media screen and (max-width: map-get($media, 'tablets')) {
        width: 250px;
        height: 250px;
    }

    @media screen and (max-width: map-get($media, 'mobilePortrait')) {
        width: 300px;
        height: 300px;
    }

    @media screen and (max-width: map-get($media, 'extraSmall')) {
        width: 200px;
        height: 200px;
    }
}

.view-item-attribute-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1em 0em;

    &__text {
        color: #ff678e;
        margin: 0;
        padding: 0;

        @media screen and (max-width: map-get($media, 'largeDisplays')) {
            font-size: 0.80em;
        }

        @media screen and (max-width: map-get($media, 'extraSmall')) {
            font-size: 0.5em;
        }
    }

    &__title {
        color: white;
        margin: 0;
        padding: 0;

        @media screen and (max-width: map-get($media, 'largeDisplays')) {
            font-size: 0.80em;
        }

        @media screen and (max-width: map-get($media, 'extraSmall')) {
            font-size: 0.5em;
        }
    }

}

